import React, { useEffect, useRef, useState } from 'react';
import { PageProps } from 'gatsby';
import { IExercise, IGroup, PageContext } from '@appTypes';
import styled from '@emotion/styled';
import SEO from '../site/components/SEO';
import ReactPlayer from 'react-player';
import { theme } from '../site/styles';
import axios from 'axios';
import CustomText from '../site/components/CustomText';
import PlayIcon from '@assets/images/play-icon.svg';

import Box from '@components/Box';
import { GRANNY_SMITH, MYSTIC, WHITE } from '@constants';
import UserIcon from '@assets/icons/UserIcon';
import ExerciseCard from '@components/Cards/v1/ExerciseCard';
import { capitalizeFirstLetter } from '@utils';
import { isBrowser } from '@utils';

const { breakpoints, colors } = theme;

const GroupDetailsPage: React.FC<PageProps<{}, PageContext>> = ({ pageContext: { locale }, location }) => {
  const [groupExercises, setGroupExercises] = useState<IExercise[]>([]);
  const [groupMetas, setGroupMetas] = useState<IGroup | null>(null);
  const [playingVideoIndex, setPlayingVideoIndex] = useState<number | null>(null);
  const [exercisePreviewPlaying, setExercisePreviewPlaying] = useState<boolean>(false);

  const videoRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    if (isBrowser()) {
      const urlParams = new URLSearchParams(window.location.search);
      const groupId = urlParams.get('groupId');
      if (groupId) {
        getGroupMetas(groupId);
        getGroupExercises(groupId);
      } else {
        window.location.href = '/';
      }
    }
  }, []);

  const getGroupMetas = async (groupId: string) => {
    try {
      const {
        data,
      }: {
        data: {
          data: IGroup;
        };
      } = await axios.get(`${process.env.GATSBY_CONTENT_API_URL}/exercise-groups/${groupId}`, {
        headers: {
          'X-Api-Key': process.env.GATSBY_CONTENT_API_KEY,
        },
      });
      if (Object.keys(data).length) {
        setGroupMetas(data.data);
      } else {
        navigateMain();
      }
    } catch (error) {
      navigateMain();
    }
  };

  const getGroupExercises = async (groupId: string) => {
    try {
      const {
        data,
      }: {
        data: {
          data: IExercise[];
        };
      } = await axios.get(`${process.env.GATSBY_CONTENT_API_URL}/exercise-groups/${groupId}/exercises`, {
        headers: {
          'X-Api-Key': process.env.GATSBY_CONTENT_API_KEY,
        },
      });
      setGroupExercises(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const navigateMain = () => {
    if (isBrowser()) {
      window.location.href = '/';
    }
  };

  const handleVideoPlay = (event: Event) => {
    const target = event.target as HTMLButtonElement;
    const cardElement = target.parentElement;
    const index = cardElement && cardElement.getAttribute('data-index');
    if (playingVideoIndex === index) {
      setPlayingVideoIndex(null);
    } else {
      setPlayingVideoIndex(Number(index));
    }
  };

  return (
    <>
      <Container>
        <SEO
          title={`Hyperhuman | Group`}
          description=''
          pathname={location.pathname}
          name={''}
          url={'https://hyperhuman.cc/group'}
          locale={locale}
        />
        <Content>
          {groupMetas && (
            <>
              <ExerciseVideo
                onClick={() => {
                  setExercisePreviewPlaying(!exercisePreviewPlaying);
                }}
              >
                {!exercisePreviewPlaying && <ExercisePlayIcon src={PlayIcon} />}
                <ReactPlayer
                  ref={videoRef}
                  width={'100%'}
                  height={'100%'}
                  playing={exercisePreviewPlaying}
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload',
                        onContextMenu: (e: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
                          e.preventDefault();
                          e.stopPropagation();
                          return false;
                        },
                      },
                    },
                  }}
                  loop={true}
                  muted={false}
                  url={groupMetas.preview.url}
                />
              </ExerciseVideo>
              <GroupMetasWrapper>
                <GroupMetasHeader>
                  <CustomText fontSize={28} lineHeight={'42px'} fontWeight={700} fontFamily={'Poppins'}>
                    {groupMetas.name}
                  </CustomText>
                  <CustomText fontSize={18} lineHeight={'23px'} fontWeight={400}>
                    {groupExercises.length} Exercises
                  </CustomText>
                </GroupMetasHeader>
                <Box display={'flex'} alignItems={'center'} my={'8px'}>
                  <Box
                    mr={'8px'}
                    borderRadius={'44px'}
                    backgroundColor={MYSTIC}
                    width={'44px'}
                    height={'44px'}
                    display='flex'
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    {groupMetas.organization.logo?.url ? (
                      <OrganizationImage src={groupMetas.organization.logo?.url} />
                    ) : (
                      <UserIcon />
                    )}
                  </Box>
                  <div>
                    <CustomText fontSize={14} lineHeight={'18px'} fontWeight={400}>
                      {groupMetas.organization.name}
                    </CustomText>
                    <CustomText fontSize={14} lineHeight={'18px'} color={GRANNY_SMITH} fontWeight={400}>
                      by {groupMetas.trainer.name}
                    </CustomText>
                  </div>
                </Box>

                <Box mb={2}>
                  <CustomText fontSize={16}>{capitalizeFirstLetter(groupMetas.kind)}</CustomText>
                  <Box mt={-1}>
                    <CustomText color={colors.grannySmith} fontSize={16}>
                      {groupMetas.rounds} rounds, {` `}
                      {groupMetas.exerciseBreakDuration > 0 &&
                        `${groupMetas.exerciseBreakDuration / 1000}s rest between exercises, `}
                      {groupMetas.roundBreakDuration / 1000}s rest between rounds
                    </CustomText>
                  </Box>
                </Box>

                <CustomText fontSize={16}>Exercise details</CustomText>
                <Box mt={-1}>
                  {groupMetas.exerciseConfig.timeBasedConfig ? (
                    <CustomText color={colors.grannySmith} fontSize={16}>
                      {groupMetas.exerciseConfig.timeBasedConfig.duration / 1000}s, {` `}
                      {groupMetas.exerciseConfig.timeBasedConfig.pace} pace
                    </CustomText>
                  ) : (
                    <CustomText color={colors.grannySmith} fontSize={16}>
                      {groupMetas.exerciseConfig.repsBasedConfig?.reps} reps, {` `}
                      {groupMetas.exerciseConfig.repsBasedConfig?.weightLevel} weight
                    </CustomText>
                  )}
                </Box>
              </GroupMetasWrapper>
            </>
          )}
          <Box height={'1px'} backgroundColor={colors.mystic} width={'100%'} mt={'38px'} mb={'54px'}></Box>
          <GroupExercisesWrapper>
            <ExercisesList>
              {groupExercises.length > 0 &&
                groupExercises.map((exercise, index) => {
                  return (
                    <ExerciseCard
                      key={index}
                      shouldPause={playingVideoIndex !== index}
                      index={index}
                      exercise={exercise}
                      onVideoPlay={handleVideoPlay}
                    ></ExerciseCard>
                  );
                })}
            </ExercisesList>
          </GroupExercisesWrapper>
        </Content>
      </Container>
    </>
  );
};
export default GroupDetailsPage;

const GroupExercisesWrapper = styled.div`
  margin-top: 30px;
`;

const GroupMetasHeader = styled.div`
  flex-direction: column;
  display: flex;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const OrganizationImage = styled.img`
  border-radius: 44px;
  height: 44px;
  object-fit: cover;
  width: 44px;
`;

const GroupMetasWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 16px;
  }
`;

const ExercisesList = styled.div`
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    margin: 0 auto;
    margin-bottom: 100px;
  }
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const ExercisePlayIcon = styled.img`
  position: absolute;
  width: 37.33px;
  height: 48px;
`;

const Content = styled.div`
  transition: all 150ms ease-in;
`;

const ExerciseVideo = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    border-radius: 26px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 94px 0px 24px 0px;
    padding: 0px;
  }
`;

const Container = styled.div`
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 1077px;
    margin: 0 auto;
    padding: 0 24px;
  }
  margin: 0px 24px 94px 24px;
  background-color: ${WHITE};
`;
