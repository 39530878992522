import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { compose, color, space, layout, SpaceProps, BorderProps, border } from 'styled-system';
import CustomText from './CustomText';
import { GRANNY_SMITH, MOUNTAIN_MEADOW, MYSTIC, OUTER_SPACE, RAIN_FOREST, WHITE } from '@constants';

interface ButtonProps {
  type?: 'primary' | 'secondary' | 'tertiary' | 'inactive' | 'white' | 'accent';
  size?: 'normal' | 'small';
  label: string;
  height?: number | string;
  width?: number | string;
  onClick?: Function;
  fontSize?: number | string;
  elementType?: 'button' | 'reset' | 'submit' | undefined;
  id?: string;
  icon?: ReactElement;
  bordered?: boolean;
  disabled?: boolean;
  uppercase?: boolean;
  background?: string;
  color?: string;
  theme?: 'light' | 'dark';
}
const Button: React.FC<ButtonProps & SpaceProps> = (props) => {
  const {
    type = 'primary',
    onClick,
    label,
    marginBottom,
    fontSize = 16,
    elementType,
    icon,
    disabled,
    uppercase = true,
    background,
    color,
    theme,
  } = props;

  const lineHeight = '18.2px';

  const getBgColor = () => {
    if (disabled) {
      return MYSTIC;
    }
    switch (type) {
      case 'primary':
        return theme === 'dark' ? WHITE : OUTER_SPACE;
      case 'secondary':
        return 'transparent';
      case 'white':
        return WHITE;
      case 'accent':
        return MOUNTAIN_MEADOW;
      case 'tertiary':
        return MYSTIC;
      default:
        return MYSTIC;
    }
  };

  const getTextColor = () => {
    if (disabled) {
      return GRANNY_SMITH;
    }
    switch (type) {
      case 'accent':
        return RAIN_FOREST;
      case 'primary':
        return theme === 'dark' ? OUTER_SPACE : WHITE;
      case 'secondary':
        return theme === 'dark' ? WHITE : OUTER_SPACE;
      case 'tertiary':
        return OUTER_SPACE;
      default:
        return GRANNY_SMITH;
    }
  };

  const getBorder = () => {
    if (disabled) {
      return '1px solid transparent';
    }
    switch (type) {
      case 'secondary':
        return '1px solid #fff';
      default:
        return '1px solid transparent';
    }
  };

  return (
    <Container
      {...props}
      bgColor={background || getBgColor()}
      mb={marginBottom}
      onClick={() => (onClick && !disabled ? onClick() : null)}
      type={elementType}
      hasIcon={icon ? true : false}
      disableHoverShadow={type !== 'primary'}
      border={getBorder()}
    >
      {icon && <IconWrapper id={props.id}>{icon}</IconWrapper>}
      <CustomText
        id={props.id}
        fontSize={fontSize}
        lineHeight={lineHeight}
        fontWeight={700}
        color={color || getTextColor()}
        uppercase={uppercase}
      >
        {label}
      </CustomText>
    </Container>
  );
};

type ContainerProps = {
  bgColor: string;
  bordered?: boolean;
  disabled?: boolean;
  hasIcon?: boolean;
  disableHoverShadow?: boolean;
};

const IconWrapper = styled.div`
  position: absolute;
  left: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.button<SpaceProps & ContainerProps & BorderProps>`
  width: 100%;
  height: 100%;
  ${compose(space, color, layout, border)};
  position: relative;
  background: ${({ bgColor }) => bgColor};
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  padding: ${({ hasIcon }) => (hasIcon ? '20px 0px 20px 32px' : '20px 24px')};
  transform: translateY(0px);
  &:hover {
    cursor: pointer;
    transform: ${({ disabled }) => (disabled ? 'translateY(0px)' : 'translateY(-3px)')};
    filter: ${({ disabled, disableHoverShadow }) =>
      disabled || disableHoverShadow ? 'none' : 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));'};
  }
  transition: all 250ms ease-in-out;
  &:focus {
    outline: none;
  }
`;

export default Button;
